
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CommentDigest"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Comment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"content"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"commentState"},"name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"node"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Article"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ArticleDigest"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"author"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserId"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"pinned"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"upvotes"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"downvotes"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":288}};
    doc.loc.source = {"body":"#import \"../article/digest.gql\"\n#import \"../user/id.gql\"\n\nfragment CommentDigest on Comment {\n  id\n  content\n  commentState: state\n  createdAt\n  node {\n    id\n    ... on Article {\n      ...ArticleDigest\n    }\n  }\n  author {\n    ...UserId\n  }\n  pinned\n  upvotes\n  downvotes\n  __typename\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../article/digest.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../user/id.gql").definitions));


      module.exports = doc;
    
